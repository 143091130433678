<template>
  <v-navigation-drawer
    v-if="!noPaths.some(p => $route.path.includes(p))"
    width="216"
    app clipped style="border-right: 2px solid #E3E9ED">

    <v-list v-if="links">
      <v-hover v-slot:default="{hover}"
               v-for="(link, n) in links"
      >

        <v-list-item
          @click="$router.push({path: link.url})"
          :key="n + '_link'"
          link
          class="d-flex pl-2 mx-2 no-background-hover rounded-lg"
          :style="hover ? 'background: #96deff33' : ''"
          :ripple="false"
          style="cursor: pointer;"
        >

          <v-list-item-icon class="justify-center text-center d-flex mr-2">
            <v-icon color="#282828" size="25">
              {{ link.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="d-flex align-center">
                  <span
                    class="font-weight-medium font-rubik"
                    style="font-size: 14px; color: #282828 !important; flex: 1"
                  >
                    {{ link.text }}</span>
              <v-chip v-if="link.chip"
                      small class="font-weight-medium font-rubik px-3"
                      color="primary"
                      style="font-size: 12px; width: fit-content !important; min-width: fit-content !important;"
              >{{ link.chip }}
              </v-chip>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-hover>
    </v-list>

    <v-divider class="mx-2"></v-divider>

  </v-navigation-drawer>
</template>

<script>
import {mdiHomeOutline} from '/src/assets/mdi.json'
import {mdiBookmarkOutline} from '/src/assets/mdi.json'
import {mdiSchoolOutline} from '/src/assets/mdi.json'
import {mdiCalculatorVariantOutline} from '/src/assets/mdi.json'
import {mdiNotebookOutline} from '/src/assets/mdi.json'


export default {
  name: "NavigationDrawer",
  data() {
    return {
      noPaths: [
        '/',
        '/dossiers-selectivitat'
      ],
      links: [
        {
          text: 'Home',
          // desc: 'Cerca carreres per veure les notes de tall i les ponderacions',
          url: '/',
          icon: mdiHomeOutline,
          mobile: true,
          appbar: true
        },
        {
          text: 'La teva biblioteca',
          desc: '',
          url: '/biblioteca',
          icon: mdiBookmarkOutline,
          mobile: true,
          appbar: true
        },
        {
          text: 'Notes de tall',
          desc: 'Cerca carreres per veure les notes de tall i les ponderacions.png',
          url: '/notes-de-tall',
          icon: mdiSchoolOutline,
          mobile: true,
          appbar: true
        },
        {
          text: 'Calculadora',
          desc: 'Calcula la teva nota de tall',
          url: '/calculadora-selectivitat',
          icon: mdiCalculatorVariantOutline,
          mobile: true,
          appbar: true
        },
        // {
        //   text: 'Blog',
        //   desc: 'Tot el que necessites saber abans de fer la selectivitat',
        //   url: '/blog',
        //   icon: 'mdi-school',
        //   mobile: true,
        //   appbar: true
        // },
        {
          text: 'Dossiers',
          desc: 'Cerca carreres per veure les notes de tall i les ponderacions',
          url: '/dossiers-selectivitat',
          icon: mdiNotebookOutline,
          chip: '2023',
          mobile: true,
          appbar: true
        },
      ],
    }
  }
}
</script>

<style scoped>

</style>

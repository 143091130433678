<template>
  <div class="d-flex flex-column"
       style="width: 100%">


    <div
      v-if="items && items.length > 0"
      class="d-flex flex-column mt-3">
      <v-card v-for="item in items"
              :key="'item-'+item.book_id"
              class="d-flex mb-6" flat
              color="transparent"
      >


        <img
          @click="onSelectSubject(item.book_id)"
          class="rounded-lg align-self-start"
          style="width: 100%; cursor: pointer; object-fit: contain !important;"
          :style="isCheckout ? 'max-width: 75px' : 'max-width: 125px'"
          :src="item.image"
        >

        <div class="d-flex flex-column mt-1 ml-4" style="flex: 1">

          <div
            class="d-flex align-start">
            <!-------- DOSSIER NAME ---------->
            <span
              @click="onSelectSubject(item.book_id)"
              style="cursor: pointer; font-size: 16px; flex: 1"
              class="font-rubik d-inline-block text-truncate font-weight-medium">{{ item.subject_name }}</span>


            <!-------- DISCOUNT PRICE ---------->
            <div class="d-flex flex-column align-end">
                <span
                  v-if="item.oldPrice"
                  class="font-weight-regular font-rubik ml-3"
                  style="font-size: 16px !important; text-decoration: line-through"
                >
                  {{ item.oldPrice }}€
                </span>

              <!--------  PRICE ---------->
              <span class="font-weight-regular font-rubik font-weight-medium"
                    :style="item.oldPrice ? 'color: red; font-size: 12px !important' : ''"
                    style="font-size: 16px !important;">{{ item.price }}€</span>

            </div>
          </div>

          <div class="d-flex align-center">
            <!-------- PAGES ---------->
            <span class="mb-2 mt-1 font-rubik" style="font-size: 14px">{{ item.pages }} pàg.</span>

            <v-spacer></v-spacer>

            <!--------  PRICE ---------->
            <span
              v-if="isCheckout"
              class="font-rubik"
              style="font-size: 14px"
            >
                  {{ item.quantity }} u.
                </span>
          </div>


          <!-------- QUANTITY SELECTOR ---------->

          <div
            v-if="!isCheckout"
            class="d-flex align-center mt-0 mb-2">
            <v-card :ripple="false"
                    class="text-center justify-center pa-0 no-background-hover font-rubik mt-1"
                    style="font-size: 12px !important;"
                    small text flat
                    @click="deleteFromCart(item)">
              ELIMINAR
            </v-card>

            <v-spacer></v-spacer>

            <div class="d-flex flex-column">
                <span style="font-size: 14px">
                  Quantitat:
                </span>
              <div class="d-flex align-center">
                <v-icon x-small
                        @click="decreaseQuantity(item.book_id)"
                        icon class="font-weight-medium mr-2" color="black">
                  {{ mdiMinus }}
                </v-icon>
                <span class="font-rubik"
                      style="font-size: 14px"
                >
                    {{ item.quantity }}</span>
                <v-icon x-small
                        @click="increaseQuantity(item.book_id)"
                        icon
                        class="font-weight-medium ml-2"
                        color="black"
                >
                  {{ mdiPlus }}
                </v-icon>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <!-------- SHIPPINGS ---------->
      <v-card
        v-if="shippings && shippings.length && shippings[selectedShipment] && shippings[selectedShipment].price > 0"
        flat color="transparent"
        class="rounded-lg align-center d-flex font-rubik mt-4"
        height="36"
        style="font-size: 14px"
      >
        <span style="flex: 1">
        Envío {{ shippings[selectedShipment].title }}
        </span>
        <span
          class="font-weight-medium"
          style="font-size: 12px">{{ shippings[selectedShipment].price.toFixed(2) }} €</span>
      </v-card>


      <!---- TOTAL PRICE ----->
      <div class="d-flex mt-4" style="font-size: 14px">
        <span class="font-weight-medium font-rubik">Total:</span>
        <v-spacer></v-spacer>

        <!-------- DISCOUNT PRICE ---------->
        <div class="d-flex flex-column align-end">
                    <span
                      v-if="totalOldPrice() !== total()"
                      class="font-weight-regular font-rubik ml-3"
                      style="font-size: 14px !important; text-decoration: line-through"
                    >
                      {{ totalOldPrice().toFixed(2) }}€
                    </span>

          <!--------  PRICE ---------->
          <span class="font-weight-medium font-rubik"
                :style="totalOldPrice() !== total() ? 'color: red; font-size: 15px !important' : ''"
                style="font-size: 15px !important;">{{ total().toFixed(2) }}€</span>
        </div>
      </div>

      <div class="text-center mt-4">
      <p
        v-if="toDiscount() > 0"
        class="font-rubik mb-0"
        style="font-size: 13px !important; line-height: 1rem !important;">
        Afegeix {{ toDiscount() }} {{ toDiscount() === 1 ? 'dossier' : 'dossiers' }} més per obtenir <span class="font-weight-medium">l'enviament gratuït</span>
      </p>
      </div>

    </div>

  </div>
</template>

<script>

import {mdiMinus, mdiPlus} from "/src/assets/mdi.json";

export default {
  name: "CheckoutItems",
  props: {
    items: Array,
    allBooks: Array,
    shippings: Array,
    selectedShipment: {
      type: Number,
      default: null
    },
    isCheckout: {
      type: Boolean,
      default: false
    }
  },
  metaInfo() {
    return {
      title: `Dossiers de la Selectivitat 2023`,
      meta: [
        {charset: 'utf-8'},
        {
          name: 'description',
          content: `Els dossiers de la selectivitat són el material idoni per estudiar per les PAU sense perdre el temps. Problemes classificats per temes i solucions al costat per autocorregir-te.`
        },
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      refreshBooks: 0,
      selectedShipping: 0,
      email: null,
      mobil: null,
      dni: null,
      url: process.env.VUE_APP_AXIOS_URI,
      isUserScrolling: false,
      postalCode: null,
      sessionid: null,

      loading: false,
      loadingCode: false,
      lineItems: [],
      minShippingPrice: 0
    }
  },
  computed: {
    cart() {
      return this.$root.cart
    },
    columns() {
      const dim = this.$vuetify.breakpoint.name;
      if (dim === 'xs')
        return 3;
      else if (dim === 'sm')
        return 4;
      else if (dim === 'md')
        return 6;
      else if (dim === 'lg')
        return 8;
      else (dim === 'xl')
      return 6;

    },
    mdiPlus() {
      return mdiPlus
    },
    mdiMinus() {
      return mdiMinus
    },
  },
  methods: {
    async submit() {
      // await this.axios.post("/shop/payment", {
      //   status: 'INCOMPLETE',
      //   dni: this.dni,
      //   email: this.email,
      //   mobile: this.mobil
      // })

      this.lineItems = [];

      for await (let item of this.items) {
        for await (let offer of item.offers) {
          const {data} = await this.axios.get(`/book/stripe-key/${item.book_id}/${offer}`)

          let idx = this.lineItems.findIndex(item => item.price === data.stripe_key)
          if (idx == -1) {
            this.lineItems.push({
              price: data.stripe_key, // The id of the one-time price you created in your Stripe dashboard
              quantity: 1,
            })
          } else {
            this.lineItems[idx].quantity++;
          }
        }
      }


      const shipping = this.shippings[this.selectedShipping];
      const shippingPrice = Math.round(shipping.price * 100);
      if (shippingPrice > 0) {
        const {data} = await this.axios.get(`/shipping/stripe-key/${shippingPrice}/${shipping.Name}`)
        if (!data || data.length === 0) alert("Si us plau, fes una foto de la pantalla posat en contacte amb examenselectivitat per acabar la teva comanda. contacte@examenselectivitat.cat")
        this.lineItems.push({
          price: data.stripe_key, // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        })
      }

      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
    roundNumber(number) {
      return Math.round(number * 100) / 100
    },
    total() {
      let sum = 0;
      this.items.forEach(item => {
        sum += item.quantity * item.price;
      })

      if (this.selectedShipment !== null)
        sum += this.shippings[this.selectedShipment].price

      return this.roundNumber(sum);
    },
    totalOldPrice() {
      let sum = 0;
      this.items.forEach(item => {
        sum += item.quantity * (item.oldPrice ? item.oldPrice : item.price);
      })

      if (this.selectedShipment !== null)
        sum += this.shippings[this.selectedShipment].price

      return this.roundNumber(sum);
    },
    toDiscount() {
      let sum = 0;
      this.items.forEach(item => {
        sum += item.quantity;
      })
      return 5 - sum;
    },
    setOffers() {

      // Set values to default
      let totalQuantity = 0;
      this.items.forEach(item => {
        item.offers = [];
        item.offerPrices = {
          offer: 0,
          normal: item.quantity
        };

        for (let i = 0; i < item.quantity; i++) {
          item.offers[i] = false;
        }
        totalQuantity += item.quantity;
      });

      // const withOffer = Math.floor(totalQuantity / 3) * 3;
      const withOffer = 0;
      // Apply offers by order
      let applied = 0;
      for (let i = 0; i < withOffer && applied < withOffer; i++) {
        for (let j = 0; j < this.items[i].quantity && applied < withOffer; j++) {
          this.items[i].offers[j] = true;
          applied++;
        }
      }

      // Merge into groups -> [0]: 8,75 or [1]: 6,95
      this.items.forEach(item => {
        const offer = item.offers.filter(o => o).length;
        item.offerPrices = {
          offer: offer,
          normal: item.offers.length - offer
        };
      });
    },
    deleteFromCart(book) {
      let list = this.$cookies.get('cart');
      this.$root.cart -= book.quantity;
      delete list[book.book_id]
      const itemIdx = this.items.findIndex(i => i.book_id === book.book_id);
      this.items.splice(itemIdx, 1)
      this.$cookies.set('cart', list)

      this.setOffers()
      if (this.postalCode && this.postalCode.length === 5) {
        this.fetchShippings()
      }
      this.refreshBooks++
    },
    decreaseQuantity(book_id) {
      this.$root.cart--;
      let list = this.$cookies.get('cart');
      if (list[book_id] > 1) {
        list[book_id]--;
        const item = this.items.find(i => i.book_id === book_id);
        item.quantity--;
      } else {
        delete list[book_id]
        const itemIdx = this.items.findIndex(i => i.book_id === book_id);
        this.items.splice(itemIdx, 1)
      }
      this.$cookies.set('cart', list)

      this.setOffers()
      if (this.postalCode && this.postalCode.length === 5) {
        this.fetchShippings()
      }
      this.refreshBooks++
    },
    increaseQuantity(book_id) {
      this.$root.cart++;
      let list = this.$cookies.get('cart');
      list[book_id]++;
      const item = this.items.find(i => i.book_id === book_id);
      item.quantity++;
      this.$cookies.set('cart', list)

      this.setOffers()
      if (this.postalCode && this.postalCode.length === 5) {
        this.fetchShippings()
      }
      this.refreshBooks++
    },
    handleScroll(event) {
      this.isUserScrolling = (window.scrollY > 80);
    },
    async onSelectSubject(book_id) {
      this.$router.push({path: '/dossiers-selectivitat/book/' + book_id})
    },
    async fetchShippings() {
      this.loadingCode = true;
      let pages = 0;
      let price = 0;
      let totalQuantity = 0;
      this.items.forEach(item => {
        price += (item.pages * 0.02 + 0.08) * item.quantity;
        pages += item.pages * item.quantity;
        totalQuantity += item.quantity;
      })

      pages = Math.round(pages / 2);

      const {data} = await this.axios.get(`/shop/shipping/${pages}`)
      this.shippings = data;


      const FREE_SHIPPING = true;
      const FREE_UNTIL_QUANTITY = 5;
      if (FREE_SHIPPING && totalQuantity >= FREE_UNTIL_QUANTITY) {
        const index = this.shippings.findIndex(s => s.title === "Estàndard");
        if (index > -1) this.shippings[index].price = 0.0;
      }

      this.selectedShipment = 0
      this.minShippingPrice = Math.min(...this.shippings.map(s => s.price))


      this.loadingCode = false;
    },
    offerImage() {
      return this.url + '/images/other/OfertaBooklets.png'
    },
    offerImageSm() {
      return this.url + '/images/other/OfertaBooklets_sm.png'
    }
  },
  watch: {
    async items(val) {
      await this.fetchShippings()
    }
  }
}
</script>

<style scoped>
.post-code-input >>> label {
  font-size: 14px !important;
}

.post-code-input >>> .v-label--active {
  top: -5px !important;
}
</style>

<template>
  <v-app :style="'background: ' + changeableBackground + ' !important'">


    <template>

      <!-------------------------------------->
      <!-- App bar                          -->
      <!-------------------------------------->

      <shop-tool-bar
        v-if="$route.path.includes('/dossiers-selectivitat') && !hidedNav.includes($route.name)"
      ></shop-tool-bar>

      <AppBar @toggleDrawer="toggleDrawer"
              @openPro="openPro"
              v-else-if="!hidedNav.includes($route.name)"
              :menu-drawer.sync="menuDrawer"
              :class="hideSubjects.includes($route.name) ? 'app-bar-shadow' : ''"
      ></AppBar>

      <!--      <subjects-menu-bar-->
      <!--        v-if="!hidedNav.includes($route.name) && !hideSubjects.includes($route.name) && !$vuetify.breakpoint.xs"-->
      <!--        :menu-drawer.sync="menuDrawer"-->
      <!--        class="pl-12"-->
      <!--      ></subjects-menu-bar>-->

      <!-------------------------------------->
      <!-- Snackbar cookies                 -->
      <!-------------------------------------->
      <!--      <cookies-dialog v-if="false && isInici && !$vuetify.breakpoint.smAndDown"></cookies-dialog>-->


      <!-------------------------------------->
      <!-- Shop Pro                         -->
      <!-------------------------------------->
      <!--      <pro-page v-if="proModel"-->
      <!--                @closePro="proModel = false"-->
      <!--                :page="proPage"-->
      <!--      ></pro-page>-->

      <!-------------------------------------->
      <!-- Content                          -->
      <!-------------------------------------->
      <v-main>

        <v-container fluid class="pa-0" style="max-width: 100% !important;">
          <!-------------------------------------->
          <!-- Vue router                       -->
          <!-------------------------------------->

          <v-overlay :value="menuDrawer"></v-overlay>

          <navigation-drawer></navigation-drawer>

<!--          <carreres-estadistica></carreres-estadistica>-->

          <router-view
            style="width: 100%"
            :style="hidedNav.includes($route.name) ? 'min-height: 100vh !important;' : 'min-height: calc(100vh - 73px) !important;'"
            id="router_height"
          >
          </router-view>


          <!-------------------------------------->
          <!-- ReCaptcha                        -->
          <!-------------------------------------->
          <recaptcha></recaptcha>

          <!-------------------------------------->
          <!-- Kill AdBlock                     -->
          <!-------------------------------------->
          <v-dialog v-model="killAdblock" persistent
                    overlay-opacity="0.8"
                    overlay-color="grey darken-1"
                    open-delay="0.4"
                    content-class="rounded-lg"
                    max-width="384">
            <v-sheet class="justify-center justify-center text-center rounded-lg"
                     style="position:relative;"
            >

              <v-icon
                @click="killAdblock=false" small
                style="position: absolute; right: 10px; top: 10px; z-index: 100" color="#97bfe2">{{ mdiClose() }}
              </v-icon>

              <v-img
                width="100%"
                src="https://examenselectivitat.cat:3000/api/images/other/enable-examenselectivitat.png"></v-img>
              <div class="px-6 py-5">
              <span style="line-height: 1.75rem;"
                    :style="$vuetify.breakpoint.xs ? 'font-size: 1rem !important' : 'font-size: 1.25rem !important'"
                    class="font-rubik mt-3 font-weight-medium">
                Ajuda'ns desactivant el teu bloquejador d'anuncis 😔
              </span>

                <v-sheet class="mt-5" color="#F1F6FF">
                      <span class="font-rubik font-weight-bold"
                            :style="$vuetify.breakpoint.xs ? 'font-size: 0.7rem !important' : 'font-size: 0.9rem !important'"
                            style="color: #4B5563">
                        Examenselectivitat és gratis i es manté amb publicitat
                      </span>
                </v-sheet>
                <div class="mt-7">
                  <v-btn
                    block height="48"
                    :style="$vuetify.breakpoint.xs ? 'font-size: 0.8rem !important' : 'font-size: 1rem !important'"
                    class="justify-center shadow-small text-none font-rubik font-weight-bold"
                    style="letter-spacing: normal !important;"
                    dark depressed color="#256EFF" @click="refresh"
                  >
                    Ja ho he desactivat
                  </v-btn>
                </div>
              </div>
            </v-sheet>
          </v-dialog>

        </v-container>
      </v-main>
    </template>

    <!--    <adsense style="opacity: 0 !important;"></adsense>-->


    <!-------------------------------------->
    <!-- Footer                           -->
    <!-------------------------------------->
    <Footer v-if="!noFooter && showFooter"></Footer>


  </v-app>
</template>

<script>

import AppBar from './components/appbar/AppBar'
import Footer from './views/politiques/Footer'
// import ProPage from "./views/shop/ProPage";
import LoginPage from './views/profile/login/LoginPage'
import {detectAnyAdblocker} from 'vue-adblock-detector'
import NavigationDrawer from "./components/NavigationDrawer";
// import CarreresEstadistica from "./components/carreres-estadistica/CarreresEstadistica.vue";

import {mdiClose, mdiTwitter} from '/src/assets/mdi.json';
import {mdiInstagram} from '/src/assets/mdi.json';
import ShopToolBar from "./views/shop/toolbar/ToolBar.vue";
import Adsense from "./components/anuncis/Adsense.vue";
import Recaptcha from "./components/captcha/recaptcha.vue";


// import CookiesDialog from './components/CookiesDialog'
// import SubjectsMenuBar from "@/components/appbar/SubjectsMenuBar";

export default {
  name: 'App',
  metaInfo: {
    title: 'Exàmens de la Selectivitat per Temes',
    titleTemplate: '%s - examenselectivitat',
    meta: [
      {charset: 'utf-8'},
      {
        name: 'description', content: 'Tots els exàmens de la Selectivitat de Catalunya ordenats per temes ' +
          'i l\'autocorrecció del problema en un sol clic. Proves des del 2023 fins al 2000.'
      },
      {name: 'viewport', content: 'width=device-width, initial-scale=1'}
    ]
  },
  components: {
    Recaptcha,
    Adsense,
    ShopToolBar,
    NavigationDrawer,
    // SubjectsMenuBar,
    // CookiesDialog,
    Footer,
    AppBar,
    LoginPage,
    // ProPage
    // CarreresEstadistica
  },
  mounted() {

    if (this.$root.currentUser === null || !this.$root.currentUser.premium) {
      (adsbygoogle = window.adsbygoogle || []).push({});
    }

    setTimeout(() => {
      detectAnyAdblocker().then((detected) => {
        const shoppingWords = ['dossiers', 'premium', 'checkout']
        if (detected && !shoppingWords.some(s => this.$route.path.includes(s))) {
          // an adblocker is detected
          // TODO; REMOVE
          // if (this.$root.currentUser === null || !this.$root.currentUser.premium) {
          //   this.killAdblock = true;
          // }
        }
      });
    }, 1000)
  },
  data() {
    return {
      showFooter: false,
      proModel: false,
      proPage: 1,
      menuDrawer: false,
      auth: false,
      min_h: 800,
      navDrawer: false,
      cookies: true,
      systemAdBar: true,
      killAdblock: false,
      hidedNav: [
        "UploadProblems",
        "AutoBuyCopi",
        "ExamPDF",
        "ReviewPage",
        "CheckOut",
        "Premium",
        "SuccessfulSubscription",
        "SuccessfulPayment",
      ],
      hideSubjects: [
        "ExamsTablePage",
        "Ads",
        "ShopPage",
        "BookPage",
        "ShopPro",
        "ShopCart",
        "AutoBuyCopi",
        "SuccessfulPayment",
        "ExamPDF",
        "Profes",
      ],
      media: [
        {
          icon: mdiTwitter,
          url: 'https://twitter.com/examensele?ref_src=twsrc%5Etfw'
        },
        {
          icon: mdiInstagram,
          url: 'https://www.instagram.com/examenselectivitat/'
        }
      ]
    }
  },
  computed: {
    isPage: function () {
      return (this.$route.path === '/upload' || this.$route.path === '/ponderacions' || this.$route.path === '/examen-del-dia-selectivitat' || this.$route.path === '/nou-professor-particular' || this.$route.params.subject_name !== null || this.isMobile)
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.name === 'md' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs'
    },
    isLatestBar() {
      return !(this.$route.path === '/404' || this.$route.path === '/ponderacions' || this.$route.path === '/upload-ponderacions' || this.$route.path === '/' || this.$route.path === '/nou-professor-particular' || this.$route.params.tema || this.$route.params.any || this.isMobile || this.$route.path === '/pare' || this.$route.path === '/errors')
    },
    isInici() {
      return this.$route.path === '/'
    },
    noFooter() {
      return this.$route.name === 'Ads' || this.$route.name === 'BookPage' || this.$route.name === 'ShopCart' || this.$route.path.includes("/selectivitat") ||
        this.$route.name === 'Configuracio' || this.$route.name === 'EmailSender'
        || this.$route.name === 'PublishArticlePage'
        || this.$route.name === 'Premium'
        || this.$route.path.includes('/selecat')
        || this.$route.path.includes('/dossiers-selectivitat/success')
        || this.$route.name === 'ReviewPage'
        || this.$route.name === 'SuccessfulSubscription'
        || this.$route.params.topic_path || this.$route.params.serie || this.$route.params.locale || this.$route.path === '/login' || this.$route.path.includes('/cercador-selectivitat') || this.$route.path.includes('/upload') || this.$route.path.includes('/pdf')
        || this.$route.path.includes('/checkout')
    },
    changeableBackground() {
      if (this.$route.params.tema || this.$route.params.any) return 'white'
      else return 'rgb(244, 244, 244)'
    }

  },
  methods: {
    mdiClose() {
      return mdiClose
    },
    goTo: function (row) {
      this.$router.push({path: row})
    },
    toggleDrawer() {
      this.navDrawer = !this.navDrawer
    },
    refresh() {
      location.reload()
    },
    openPro(page) {
      this.proPage = page;
      this.proModel = true;

    }
  },
  watch: {
    // TODO; UNCOMMENT
    '$root.currentUser': {
      immediate: true,
      async handler(val) {
        if (val && this.$cookies.get("token")) {
          const {data} = await this.axios.post('/premium/plan', {
            user_token: this.$cookies.get("token")
          })
          if (data.active) {
            this.$root.currentUser.premium = true;
          } else {
            this.$root.currentUser.premium = false;
          }
        }
      }
    },
    '$route.path': {
      immediate: true,
      async handler(val) {

        this.showFooter = false;
        setTimeout(() => {
          this.showFooter = true;
        }, 2000)


        document.body.scrollTop = 0 // For Safari
        document.documentElement.scrollTop = 0 // For Chrome,Firefox, IE and Opera

        const subject_name = this.$route.params.subject_name;
        if (subject_name && !this.$route.path.includes("upload")) {
          const redirectSubjects = {
            "alemany": 'Alemany',
            "analisi-musical": 'Anàlisi musical',
            "angles": 'Anglès',
            "biologia": 'Biologia',
            "ciencies-de-la-terra": 'Ciències de la terra',
            "cultura-audiovisual": 'Cultura audiovisual',
            "dibuix-artistic": 'Dibuix artistic',
            "dibuix-tecnic": 'Dibuix tècnic',
            "disseny": 'Disseny',
            "economia-de-l-empresa": 'Economia de l\'empresa',
            "electrotecnia": 'Electrotècnia',
            "fisica": 'Física',
            "fonaments-de-les-arts": 'Fonaments de les arts',
            "frances": 'Francès',
            "geografia": 'Geografia',
            "grec": 'Grec',
            "historia": 'Història',
            "historia-de-l'art": 'Historia de l\'art',
            "historia-de-la-filosofia": 'Història de la filosofia',
            "italia": 'Italià',
            "literatura-castellana": 'Literatura castellana',
            "literatura-catalana": 'Literatura catalana',
            "llati": 'Llatí',
            "llengua-castellana": 'Llengua castellana',
            "llengua-catalana": 'Llengua catalana',
            "matematiques": 'Matemàtiques',
            "matematiques-cs": 'Matemàtiques C.S.',
            "quimica": 'Química',
            "tecnologia-industrial": 'Tecnologia  industrial'
          }


          const name = redirectSubjects[subject_name];
          if (name) await this.$router.push({path: `/selectivitat/${name}`});
        }


      }
    },
    '$root.showCaptcha'(val) {
      if (val) {
        const x = window.scrollX;
        const y = window.scrollY;
        window.onscroll = function () {
          window.scrollTo(x, y);
        };
      } else {
        window.onscroll = function () {
        };
        this.$forceUpdate();
      }

    }
  }
}
</script>

<style>

*, html {
  /*scroll-behavior: smooth !important;*/
}

.font-rubik {
  font-family: Rubik, sans-serif !important;
}

.v-navigation-drawer__border {
  display: none !important;
}

.shadow {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
}

.shadow-small {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1) !important;
}

.shadow-sm {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.app-bar-shadow {
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px !important;
}

h1, h2, h3, h4, p {
  font-display: swap !important;
}

.hide_file {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 24px;
  width: 100%;
  opacity: 0;
}

.centered {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.first {
  position: absolute;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.2s ease-in-out;
  font-size: 17px;
  text-transform: uppercase;
  color: grey;
  letter-spacing: 2px;
  text-align: center;
  vertical-align: middle;
  height: 100%;
  border: 1px solid #999999;
  border-radius: 5px;

}

.first {
  position: absolute;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.2s ease-in-out;
  font-size: 17px;
  text-transform: uppercase;
  color: white;
  letter-spacing: 2px;
  text-align: center;
  vertical-align: middle;
  height: 100%;
  border: 10px dashed white;
  border-radius: 5px;

}

.v-lazy-image {
  filter: blur(2px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}

.hide_file {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 24px;
  width: 100%;
  opacity: 0;
}

.grey {
  background-color: #aaaaaa;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

::spelling-error {
  color: black;
  text-decoration: none;
  caret-color: black;
}

.curly-underline {
  color: black;
  text-decoration: none;
  caret-color: black;
}

a, .router-link-exact-active, .router-link-active {
  text-decoration: none;
  color: white;
}

.list {
  width: 100%;
  border-left: transparent 3px solid;
}

.list:hover {
  height: 100%;
  border-left: rgb(166, 186, 231) 3px solid;
}

.recaptcha-accessible-status {
  opacity: 0 !important
}

.v-btn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

p {
  font-size: 1.1875rem !important;
  line-height: 1.8;
  font-family: Rubik, sans-serif !important;
}

ul p {
  line-height: 1.3;
}

.ul-text {
  font-size: 1.1875rem;
  line-height: 1;
}

h1, h2, h3, .pmb-title {
  color: #333333;
  font-weight: bold;
  font-family: Rubik, sans-serif !important;
}

.visible-a {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

/* ------------------------------- */
/* ------------------------------- */
/* ---------- RESPONSIVE---------- */
/* ------------------------------- */
/* ------------------------------- */

@media (min-width: 992px) {
  .container {
    max-width: 900px !important;
  }

  .e-text {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

}

@media (max-width: 992px) {

  .container {
    max-width: 530px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  h3 {
    font-size: 19px !important;
  }

  p {
    font-size: 17px !important;
    line-height: 2;
  }

  .image-figure {
    order: 1;
  }

  .intro {
    order: 2;
  }

}

a {
  outline: none;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.a-tag:hover {
  text-decoration: underline !important;
}

.fullwidth {
  width: 100%
}

img {
  width: 100% !important;
}

#col_bar {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 23% !important;
  flex: 0 0 23% !important;
  height: 100vh !important;
}

.verticalNav {
  display: table-cell;
  position: relative;
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, .08);
  z-index: 100;
  height: 100vh !important;
  width: 100vw;
  background-color: white !important;
}

#col_all {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 77% !important;
  flex: 0 0 77% !important;
  max-width: 77% !important;
}

#col_normal {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 100% !important;
  flex: 0 0 100% !important;
  min-width: 100% !important;
  width: 100vw !important;
}

html {
  height: 100% !important;
}

body {
  min-height: 100% !important;
  background: rgb(244, 244, 244) !important;
  font-family: Rubik, sans-serif !important;
}

.v-navigation-drawer {
  will-change: initial;
}

.bottom-caption {
  font-size: 10px !important;
  line-height: 12px !important;
  max-height: 24px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  font-weight: 400 !important;
  overflow-wrap: break-word !important;
  color: #717171 !important;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.system-bar-items {
  font-family: Rubik, sans-serif !important;
  display: flex;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-flow: row nowrap;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  margin: 0;
  background: none;
  border: 0;
  color: #1e3c87;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
}

@font-face {
  /*font-family: "Euclid Circular A" !important;*/
  /*src: url("//db.onlinewebfonts.com/t/a575313c6dc4fd00c1a9506e1c3ea4fc.eot");*/
  /*src: url("//db.onlinewebfonts.com/t/a575313c6dc4fd00c1a9506e1c3ea4fc.eot?#iefix") format("embedded-opentype"),*/
  /*url("//db.onlinewebfonts.com/t/a575313c6dc4fd00c1a9506e1c3ea4fc.woff2") format("woff2"),*/
  /*url("//db.onlinewebfonts.com/t/a575313c6dc4fd00c1a9506e1c3ea4fc.woff") format("woff"),*/
  /*url("//db.onlinewebfonts.com/t/a575313c6dc4fd00c1a9506e1c3ea4fc.ttf") format("truetype"),*/
  /*url("//db.onlinewebfonts.com/t/a575313c6dc4fd00c1a9506e1c3ea4fc.svg#Euclid Circular A") format("svg");*/
}


</style>

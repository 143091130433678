import axios from "axios";
import SubjectBranch from "./SubjectBranch";

export default class Branches {
  public m_subjectsBranches: SubjectBranch[] = [];

  constructor(subjectsBranches: SubjectBranch[]) {
    this.m_subjectsBranches = subjectsBranches;
  }

  static async build() {
    // const data = await Branches.fetchSubjectsBranches();
    const data = [
      {
        "branch_name": "Comunes",
        "subjects": [
          {
            "subject_id": "1",
            "subject_name": "Anglès",
            "subject_icon": "mdi-chess-queen",
            "subject_path": "angles"
          },
          {
            "subject_id": "3",
            "subject_name": "Llengua castellana",
            "subject_icon": "mdi-fountain-pen-tip",
            "subject_path": "llengua-castellana"
          },
          {
            "subject_id": "4",
            "subject_name": "Llengua catalana",
            "subject_icon": "mdi-book-open-variant",
            "subject_path": "llengua-catalana"
          },
          {
            "subject_id": "2",
            "subject_name": "Història",
            "subject_icon": "mdi-chess-rook",
            "subject_path": "historia"
          },
          {
            "subject_id": "21",
            "subject_name": "Història de la filosofia",
            "subject_icon": "mdi-lightbulb-on-outline",
            "subject_path": "historia-de-la-filosofia"
          },
        ]
      },
      {
        "branch_name": "Ciència i Tecnologia",
        "subjects": [
          {
            "subject_id": "5",
            "subject_name": "Biologia",
            "subject_icon": "mdi-bacteria",
            "subject_path": "biologia"
          },
          {
            "subject_id": "6",
            "subject_name": "Ciències de la terra",
            "subject_icon": "mdi-image-filter-vintage",
            "subject_path": "ciencies-de-la-terra"
          },
          {
            "subject_id": "7",
            "subject_name": "Dibuix tècnic",
            "subject_icon": "mdi-android-studio",
            "subject_path": "dibuix-tecnic"
          },
          {
            "subject_id": "8",
            "subject_name": "Electrotècnia",
            "subject_icon": "mdi-chip",
            "subject_path": "electrotecnia"
          },
          {
            "subject_id": "9",
            "subject_name": "Física",
            "subject_icon": "mdi-rocket",
            "subject_path": "fisica"
          },
          {
            "subject_id": "11",
            "subject_name": "Matemàtiques",
            "subject_icon": "mdi-pi-box",
            "subject_path": "matematiques"
          },
          {
            "subject_id": "10",
            "subject_name": "Química",
            "subject_icon": "mdi-flask-empty",
            "subject_path": "quimica"
          },
          {
            "subject_id": "12",
            "subject_name": "Tecnologia industrial",
            "subject_icon": "mdi-boom-gate-up",
            "subject_path": "tecnologia-industrial"
          }
        ]
      },
      {
        "branch_name": "Humanitats i Socials\t",
        "subjects": [
          {
            "subject_id": "18",
            "subject_name": "Economia de l'empresa",
            "subject_icon": "mdi-currency-usd",
            "subject_path": "economia-de-l-empresa"
          },
          {
            "subject_id": "19",
            "subject_name": "Geografia",
            "subject_icon": "mdi-earth",
            "subject_path": "geografia"
          },
          {
            "subject_id": "26",
            "subject_name": "Història de l'art",
            "subject_icon": "mdi-brush",
            "subject_path": "historia-de-l-art"
          },
          {
            "subject_id": "22",
            "subject_name": "Literatura castellana",
            "subject_icon": "mdi-wind-turbine",
            "subject_path": "literatura-castellana"
          },
          {
            "subject_id": "23",
            "subject_name": "Literatura catalana",
            "subject_icon": "mdi-lighthouse",
            "subject_path": "literatura-catalana"
          },
          {
            "subject_id": "25",
            "subject_name": "Matemàtiques CC.SS.",
            "subject_icon": "mdi-equalizer",
            "subject_path": "matematiques-cs"
          }
        ]
      },
      {
        "branch_name": "Arts",
        "subjects": [
          {
            "subject_id": "13",
            "subject_name": "Anàlisi musical",
            "subject_icon": "mdi-music",
            "subject_path": "analisi-musical"
          },
          {
            "subject_id": "17",
            "subject_name": "Cultura audiovisual",
            "subject_icon": "mdi-movie-open",
            "subject_path": "cultura-audiovisual"
          },
          {
            "subject_id": "14",
            "subject_name": "Dibuix artístic",
            "subject_icon": "mdi-camera-image",
            "subject_path": "dibuix-artistic"
          },
          {
            "subject_id": "15",
            "subject_name": "Disseny",
            "subject_icon": "mdi-auto-fix",
            "subject_path": "disseny"
          },
          {
            "subject_id": "16",
            "subject_name": "Fonaments de les arts",
            "subject_icon": "mdi-palette",
            "subject_path": "fonaments-de-les-arts"
          }
        ]
      },
      {
        "branch_name": "Clàssiques i estrangeres",
        "subjects": [
          {
            "subject_id": "29",
            "subject_name": "Alemany",
            "subject_icon": "mdi-glass-mug-variant",
            "subject_path": "alemany"
          },
          {
            "subject_id": "28",
            "subject_name": "Francès",
            "subject_icon": "mdi-food-croissant",
            "subject_path": "frances"
          },
          {
            "subject_id": "20",
            "subject_name": "Grec",
            "subject_icon": "mdi-alpha",
            "subject_path": "grec"
          },
          {
            "subject_id": "27",
            "subject_name": "Italià",
            "subject_icon": "mdi-pizza",
            "subject_path": "italia"
          },
          {
            "subject_id": "24",
            "subject_name": "Llatí",
            "subject_icon": "mdi-pillar",
            "subject_path": "llati"
          }
        ]
      }
    ]
    const branches = data.map((obj) => new SubjectBranch(obj));
    return new Branches(branches);
  }

  private static async fetchSubjectsBranches(): Promise<SubjectBranch[]> {
    try {
      const {data} = await axios.get('/subjects-branches');
      return data
    } catch (e) {
      console.error(e);
    }
  }


  public getSubjectsBranches(): SubjectBranch[] {
    return this.m_subjectsBranches;
  }


}
